/* ✅ Ensure all images have a consistent size */
.responsive-img {
  width: 100%;
  max-width: 600px; /* ✅ Ensures images don’t exceed 600px */
  height: auto; /* ✅ Maintains aspect ratio */
  display: block;
  margin: 10px auto; /* ✅ Centers images */
  object-fit: cover; /* ✅ Prevents image distortion */
  border-radius: 8px; /* ✅ Optional: rounded corners */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); /* ✅ Adds slight shadow */
}

/* ✅ Ensure consistent spacing & typography */
body {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
}

/* ✅ Blog Post Titles */
.blog-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
